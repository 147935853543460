import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import Swal from "sweetalert2";
import AsyncSelect from "../common/AsyncSelect";
import CustomSelect from "../common/Select";
import { IntervalsOpt } from "../../../utils/constants";
import { getRequiredValueSchema } from "../../../utils/General";

// Validation schema using Zod
const schema = z.object({
  partnerName: z.string().min(2, "Partner Name is required"),
  promoCode: z.string().min(2, "Promo Code is required"),
  package_id: getRequiredValueSchema("Package", z.number),
  trialPeriod: getRequiredValueSchema("Free Trial Period", z.string),
  number: z.preprocess(
    (val) => (val === "" ? undefined : Number(val)),
    z
      .number({
        required_error: "Number is required",
      })
      .min(1, "The number must be greater than 0")
  ),
});

// Higher-Order Component to inject react-hook-form into class component
const withHookForm = (Component) => (props) => {
  const { code } = props;

  // Use useForm hook with the resolver and default values
  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      partnerName: code?.partner?.name || "",
      promoCode: code?.value || "",
      package_id: { label: code?.package?.title, value: code?.package?.id },
      trialPeriod:
        {
          label: code.free_trial_period_interval,
          value: code.free_trial_period_interval,
        } || null, // Ensure it's set correctly
      number: code.free_trial_period_no || "",
    },
  });

  // Reset form when initial values change
  React.useEffect(() => {
    methods.reset({
      partnerName: code?.partner?.name || "",
      promoCode: code?.value || "",
      package_id:
        { label: code?.package?.title, value: code?.package?.id } || "",
      trialPeriod:
        {
          label: code.free_trial_period_interval,
          value: code.free_trial_period_interval,
        } || {}, // Ensure it's set correctly
      number: code.free_trial_period_no || "",
    });
  }, [code, props.packageOptions, methods]); // Only re-run when code change

  return <Component {...props} {...methods} />;
};

class CodesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      packageOptions: [],
    };
    // Bind _addCode to the class instance
    this._addCode = this._addCode.bind(this);
  }

  _fetchPackages() {
    Backend.getPackages()
      .then((resp) => {
        this.setState({ packageOptions: resp.results });
      })
      .catch((error) => {
        Notify.error(error.message);
      });
  }

  componentDidMount() {
    this._fetchPackages();
  }

  isUpdating = this.props?.code?.value;

  _addCode(values) {
    this.setState({ isLoading: true });
    let message = "Added the partner code successfully.";

    const reqObj = {
      partner_name: values.partnerName,
      value: values.promoCode,
      free_trial_period_no: values.number,
      free_trial_period_interval: values.trialPeriod,
      package: values.package_id,
    };
    Backend.addCode(reqObj)
      .then((data) => {
        this.props.onUpdate();
        this.setState({
          isLoading: false,
        });
        Swal.fire({
          title: "Done",
          text: message,
          icon: "success",
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops...",
          text: error?.message?.split(":")[1],
          icon: "error",
          timerProgressBar: true,
          confirmButtonText: "Okay",
        });

        this.setState({
          isLoading: false,
        });
      });
  }

  _updateCode(values) {
    this.setState({ isLoading: true });
    let message = "Updated the partner code details successfully.";

    const reqObj = {
      partner_name: values.partnerName,
      value: values.promoCode,
      free_trial_period_no: values.number,
      free_trial_period_interval: values.trialPeriod,
      package: values.package_id,
    };
    const reqId = this.props.code.id;

    Backend.updateCode(reqObj, reqId)
      .then((data) => {
        this.props.onUpdate();
        this.setState({
          isLoading: false,
        });
        Swal.fire({
          title: "Done",
          text: message,
          icon: "success",
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops...",
          text: error?.message?.split(":")[1],
          icon: "error",
          timerProgressBar: true,
          confirmButtonText: "Okay",
        });
        this.setState({
          isLoading: false,
        });
      });
  }

  _onSubmit = (values) => {
    if (this.isUpdating) {
      this._updateCode(values);
    } else {
      this._addCode(values);
    }
  };

  render() {
    const {
      register,
      handleSubmit,
      formState: { errors },
      control,
    } = this.props;

    const isUpdating = this.props.code.partnerName;

    const { isLoading } = this.state;

    return (
      <Modal show={this.props.show}>
        <div className="modal-content">
          <Modal.Header>
            <Modal.Title>
              {isUpdating ? "Edit Partner Code" : "New Partner Code"}{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(this._onSubmit)}>
              {/* Partner Name */}
              <div className="form-group row mb-4">
                <div className="col-md-12">
                  <label className="form-label">Partner Name</label>
                  <input
                    type="text"
                    className="form-control mb-2 mb-md-0"
                    placeholder="Enter Partner Name"
                    disabled={this.isUpdating}
                    {...register("partnerName")}
                  />
                  {errors.partnerName && (
                    <p className="text-danger">{errors.partnerName.message}</p>
                  )}
                </div>
                <div className="form-text">
                  This is the name of the Partner and it will not be shown.
                </div>
              </div>

              {/* Promo Code */}
              <div className="form-group row mb-4">
                <div className="col-md-12">
                  <label className="form-label">Promo Code</label>
                  <input
                    type="text"
                    className="form-control mb-2 mb-md-0"
                    placeholder="Enter Code"
                    {...register("promoCode")}
                  />
                  {errors.promoCode && (
                    <p className="text-danger">{errors.promoCode.message}</p>
                  )}
                </div>
                <div className="form-text">
                  The promo code you will give to the partner.
                </div>
              </div>

              <div className="form-group row mb-4">
                <div className="col-md-12">
                  <label className="form-label">Packages</label>
                  <Controller
                    control={control}
                    name="package_id"
                    render={({ onChange, ref, field }) => (
                      <AsyncSelect
                        endpoint={`${window.Api.Packages}`}
                        getOptions={(result) => {
                          const options = result.map((item) => ({
                            label: item.title,
                            value: item.id,
                            data: item,
                          }));
                          return options;
                        }}
                        onSelected={(value) => {
                          field.onChange({
                            value: value.id,
                            label: value.title,
                          });
                        }}
                        value={field?.value?.label ? field?.value : ""}
                        placeholder="Select..."
                      />
                    )}
                  />

                  {errors.package_id && (
                    <p className="text-danger">{errors.package_id.message}</p>
                  )}
                  <div className="form-text">
                    The Package you will like to provide to the customer.
                  </div>
                </div>
              </div>

              {/* Free Trial Period */}
              <div className="form-group row mb-4">
                <div className="col-md-12">
                  <label className="form-label">Free Trial Period</label>
                  <Controller
                    control={control}
                    name="trialPeriod"
                    render={({ onChange, ref, field }) => (
                      <CustomSelect
                        options={IntervalsOpt}
                        onChange={(value) => {
                          field.onChange({
                            value: value.value,
                            label: value.label,
                          });
                        }}
                        value={field?.value?.label ? field?.value : ""}
                        placeholder="Select..."
                      />
                    )}
                  />

                  {errors.trialPeriod && (
                    <p className="text-danger">{errors.trialPeriod.message}</p>
                  )}
                  <div className="form-text">
                    The time frame for which the code will be valid.
                  </div>
                </div>
              </div>

              {/* Number */}
              <div className="form-group row mb-4">
                <div className="col-md-12">
                  <label className="form-label">Enter Number</label>
                  <input
                    type="number"
                    className="form-control mb-2 mb-md-0"
                    placeholder="E.g 2"
                    {...register("number")}
                  />
                  {errors.number && (
                    <p className="text-danger">{errors.number.message}</p>
                  )}
                </div>
                <div className="form-text">
                  The value of time frame you selected above.
                </div>
              </div>

              <div className="modal-footer p-0 pt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => this.props.onHide()}
                >
                  Close
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="btn btn-primary"
                >
                  Save changes
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  }
}

// Wrap component with default initial values
export default withHookForm(CodesModal);
