import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import Header from "./components/Header";
import LeftMenu from "./components/LeftMenu";
import Footer from "./components/Footer";
import ScrollTop from "./components/ScrollTop";

import Dashboard from "./Dashboard";
import Downloads from "./Downloads";
import ChurnRate from "./ChurnRate";
import Revenue from "./Revenue";

import AccountSettings from "./components/AccountSettings";
import Codes from "./Codes";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <LeftMenu />

            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <Header />
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div className="d-flex flex-column-fluid mt-3">
                  <Switch>
                    <Route exact path="/" component={Dashboard} />

                    <Route exact path="/downloads" component={Downloads} />

                    <Route exact path="/churn-rate" component={ChurnRate} />

                    <Route exact path="/revenue" component={Revenue} />

                    <Route exact path="/codes" component={Codes} />

                    <Route
                      exact
                      path="/account-settings"
                      component={AccountSettings}
                    />

                    <Redirect to="/404" />
                  </Switch>
                </div>
              </div>
              <Footer />
            </div>
          </div>
          <ScrollTop />
        </div>
      </>
    );
  }
}
