import React, { Component } from "react";

import moment from "moment";

import { Line } from "react-chartjs-2";

import Backend from "../utils/Backend";
import Notify from "../utils/Notify";

import LatestUsersTable from "./components/tables/latest-users/LatestUsersTable";

import StatsCard from "./components/stats-cards/StatsCard";

export default class ChurnRate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statInfo: null,
      graphStats: null,
    };
  }

  componentDidMount() {
    this._getStats();
    this._getGraphStats();
  }

  _getGraphStats() {
    Backend.getUnsubscriptionsStats()
      .then((graphStats) => {
        this.setState({ graphStats, isLoading: false });
        let graphMap = graphStats?.stats?.daily_unsubscriptions;

        const date = graphMap?.map((statInfo) => statInfo?.date);

        const totalUnsubscriptions = graphMap?.map(
          (statInfo) => statInfo?.total_unsubscriber_count
        );

        let graphData = {
          labels: date,

          datasets: [
            {
              label: "Unsubscriptions",
              data: totalUnsubscriptions,
              fill: true,
              borderColor: "#FFB800",
              borderWidth: 4,
              pointDot: true,
              backgroundColor: "#FFEAB3",
            },
          ],
        };

        this.setState({
          graphs: graphData,
          isLoading: false,
        });

        setTimeout(() => {
          this.setState({
            isLoading: false,
          });
        }, 300);
      })
      .catch((error) => {
        Notify.error(error.message);
      });
  }

  _renderGraphs() {
    let { graphs, isLoading } = this.state;

    if (isLoading) {
      return null;
    }

    let filteredData = graphs?.datasets[0]?.data.map((value) =>
      Math.max(0, value)
    );
    if (filteredData) {
      graphs.datasets[0].data = filteredData;
    }

    return (
      <>
        <div className="reset-btn ml-5"></div>
        <Line
          ref={(reference) => (this.chartRef = reference)}
          data={graphs}
        />
      </>
    );
  }

  _getStats() {
    this.setState({ isLoading: true });

    Backend.getStats()
      .then((stats) => {
        this.setState({ statInfo: stats, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    let { statInfo } = this.state;

    if (!statInfo) {
      return null;
    }

    const currentYear = moment().year();
    const currentMonth = moment().month() + 1;

    const currentMonthStat = statInfo.stats?.monthly.find((stat) => {
      const [year, month] = stat.date.split("-").map(Number);
      return year === currentYear && month === currentMonth;
    });

    const weeklyStats = statInfo.stats?.week;

    return (
      <>
        <div className="container">
          <h1 className=" fw-bold ff-bitter mt-2 mb-10 fs-xl-1">Churn Rate </h1>
          <div className="row wd-100">
            <div className="col-lg-4 mb-4">
              <StatsCard
                data={[
                  {
                    statsInfo: `${weeklyStats?.lite_unsubscriber_count}`,
                    title: "Lite Unsubcriptions this week",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x={7}
                          y={4}
                          width={3}
                          height={13}
                          rx="1.5"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x={12}
                          y={9}
                          width={3}
                          height={8}
                          rx="1.5"
                        />
                        <path
                          d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
                          fill="#000000"
                          fillRule="nonzero"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x={17}
                          y={11}
                          width={3}
                          height={6}
                          rx="1.5"
                        />
                      </g>
                    ),
                  },
                ]}
              />
            </div>
            <div className="col-lg-4 mb-4">
              <StatsCard
                data={[
                  {
                    statsInfo: `${weeklyStats?.pro_unsubscriber_count}`,
                    title: "Pro Unsubcriptions this week",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x={7}
                          y={4}
                          width={3}
                          height={13}
                          rx="1.5"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x={12}
                          y={9}
                          width={3}
                          height={8}
                          rx="1.5"
                        />
                        <path
                          d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
                          fill="#000000"
                          fillRule="nonzero"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x={17}
                          y={11}
                          width={3}
                          height={6}
                          rx="1.5"
                        />
                      </g>
                    ),
                  },
                ]}
              />
            </div>
            <div className="col-lg-4 mb-4">
              <StatsCard
                data={[
                  {
                    statsInfo: `${currentMonthStat?.total_unsubscriber_count}`,
                    title: "Total Unsubcriptions this month",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={10}
                        />
                        <path
                          d="M6.16794971,14.5547002 C5.86159725,14.0951715 5.98577112,13.4743022 6.4452998,13.1679497 C6.90482849,12.8615972 7.52569784,12.9857711 7.83205029,13.4452998 C8.9890854,15.1808525 10.3543313,16 12,16 C13.6456687,16 15.0109146,15.1808525 16.1679497,13.4452998 C16.4743022,12.9857711 17.0951715,12.8615972 17.5547002,13.1679497 C18.0142289,13.4743022 18.1384028,14.0951715 17.8320503,14.5547002 C16.3224187,16.8191475 14.3543313,18 12,18 C9.64566871,18 7.67758127,16.8191475 6.16794971,14.5547002 Z"
                          fill="#000000"
                          transform="translate(12.000000, 15.499947) scale(1, -1) translate(-12.000000, -15.499947) "
                        />
                      </g>
                    ),
                  },
                ]}
              />
            </div>
          </div>
          <div className="row wd-100">
            <div className="col-md-12">
              <div className="card card-custom gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Churn Rate</h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="card-body">
                  {this._renderGraphs()}
                  <div id="churn_rate" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mt-5">
            <LatestUsersTable
              title="Latest Users Dropped Out"
              text="Unsubscribed On"
            />
          </div>
        </div>
      </>
    );
  }
}
