import React from "react";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import BaseTable from "../BaseTable";
import ConfirmationModal from "../../modals/ConfirmationModal";
import Backend from "../../../../utils/Backend";
import Notify from "../../../../utils/Notify";
import CodesModal from "../../modals/CodesModal";

class CodesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      code: {},
      showCodeModal: false,
      isLoading: false,
      pauseUnpauseModalInfo: {
        show: false,
        status: "",
        body: "",
        confirmBtnText: "",
        id: "",
      },
    };
    this.table = React.createRef();

    this.handleModal = this.handleModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  handlePause = (id) => {
    this.setState({
      pauseUnpauseModalInfo: {
        show: true,
        status: "active",
        body: "You are about to Pause this promo code",
        confirmBtnText: "Yes, Pause!",
        id,
      },
    });
  };

  handleUnPause = (id) => {
    this.setState({
      pauseUnpauseModalInfo: {
        show: true,
        status: "inactive",
        body: "You are about to unpause this promo code",
        confirmBtnText: "Yes, Unpause!",
        id,
      },
    });
  };

  closePauseUnpauseModal = () => {
    this.setState({
      pauseUnpauseModalInfo: {
        show: false,
        status: "",
        body: "",
        confirmBtnText: "",
        id: "",
      },
    });
  };

  handleModal = () => {
    this.setState({
      code: {},
      showCodeModal: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showCodeModal: false,
    });
  };

  onUpdate = () => {
    this.table.current.refresh();
    this.setState({
      showCodeModal: false,
    });
  };

  _toggleStatus = () => {
    let { id, status } = this.state.pauseUnpauseModalInfo;
    const reqObj = {
      status: status === "active" ? "inactive" : "active",
    };
    this.setState({
      isLoading: true,
    });
    Backend.updateCode(reqObj, id)
      .then((resp) => {
        this.onUpdate();
        this.props.getStats();

        this.setState({
          isLoading: false,
        });

        this.closePauseUnpauseModal();

        Swal.fire({
          title: "Done",
          text: `Promo code has been successfully ${
            resp?.status === "active" ? "unpaused" : "paused"
          }`,
          icon: "success",
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  };

  _getInterval = (period, interval) => {
    if (period === 1) {
      const intervalArr = interval?.split("");
      const lastCharArr = intervalArr[intervalArr?.length - 1];
      if (lastCharArr == "s") {
        intervalArr.pop();
        const updatedInterval = intervalArr.join("");
        return updatedInterval;
      }
    }
    return interval;
  };

  _getColumns() {
    let dateText = "Date";

    return [
      {
        Header: () => (
          <span className="p-0 text-muted fw-bolder">Partner Name</span>
        ),
        id: "partner_name.name",
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let data = rowInfo.original;

          return (
            <>
              <span className="text-dark fw-bolder text-hover-primary fs-6">
                {data?.partner?.name || "-"}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <span className="p-0 text-muted fw-bolder">Codes</span>,
        id: "code",
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let data = rowInfo.original;

          return (
            <>
              <span className="text-dark text-hover-primary fw-bolder">
                {data.value || "-"}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <span className="p-0 text-muted fw-bolder">Package</span>,
        id: "code",
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let data = rowInfo.original;

          return (
            <>
              <span className="text-dark text-hover-primary fw-bolder">
                {data.package.title || "-"}
              </span>
            </>
          );
        },
      },

      {
        Header: () => (
          <span className="p-0 text-muted fw-bolder">Free Trial Periods</span>
        ),
        id: "status",
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let data = rowInfo.original;

          return (
            <>
              <span className="text-dark text-hover-primary fw-bolder">
                {`${data.free_trial_period_no} ${this._getInterval(
                  data.free_trial_period_no,
                  data.free_trial_period_interval
                )}` || "-"}
              </span>
            </>
          );
        },
      },

      {
        Header: () => <span className="p-0 text-muted fw-bolder">Status</span>,
        id: "status",
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let data = rowInfo.original;
          const status =
            data.status === "active" ? (
              <span className="badge badge-light-success">Active</span>
            ) : (
              <span className="badge badge-light-danger">Paused</span>
            );
          return (
            <>
              <span className="text-dark fw-bolder text-hover-primary fs-6">
                {status || "-"}
              </span>
            </>
          );
        },
      },
      {
        Header: () => (
          <span className="p-0 text-muted fw-bolder">No. Of Times Used</span>
        ),
        id: "status",
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let data = rowInfo.original;

          return (
            <>
              <span className="text-dark fw-bolder text-hover-primary fs-6">
                {data?.usage_no === 0 ? 0 : data?.usage_no || "-"}
              </span>
            </>
          );
        },
      },
      {
        Header: "Actions",
        id: "",
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let data = rowInfo.original;
          return (
            <div className={"my-auto float-first"}>
              <button
                type="button"
                className="btn btn-bg-light btn-active-color-primary btn-sm me-1"
                onClick={() => {
                  this.setState({
                    code: data,
                    showCodeModal: true,
                  });
                }}
              >
                Edit
              </button>
              {data?.status === "active" ? (
                <button
                  type="button"
                  className="btn btn-bg-light btn-active-color-primary btn-sm me-1"
                  onClick={() => this.handlePause(data?.id)}
                >
                  Pause
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-bg-light btn-active-color-primary btn-sm me-1"
                  onClick={() => this.handleUnPause(data?.id)}
                >
                  Unpause
                </button>
              )}
            </div>
          );
        },
      },
    ];
  }

  renderToolbar = () => {
    return (
      <div class="card-toolbar">
        <div class="d-flex align-items-center position-relative my-1 me-5">
          <button
            type="button"
            onClick={() => {
              this.handleModal();
            }}
            className="btn btn-primary"
          >
            Add New Code
          </button>
        </div>
      </div>
    );
  };

  render() {
    const columns = this._getColumns();

    let endpoint = window.Api.Codes;

    return (
      <>
        <BaseTable
          ref={this.table}
          title={this.props.title}
          loading={false}
          endpoint={endpoint}
          noDataMessage={"No Partner Code Found."}
          columns={columns}
          showPagination={true}
          showSearch={false}
          showExport={false}
          renderToolbar={this.renderToolbar}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
        />

        {this.state.showCodeModal && (
          <CodesModal
            show={this.state.showCodeModal}
            onHide={this.handleCloseModal}
            code={this.state.code}
            onUpdate={() => this.onUpdate()}
          />
        )}

        {this.state.pauseUnpauseModalInfo.show && (
          <ConfirmationModal
            show={this.state.pauseUnpauseModalInfo.show}
            body={this.state.pauseUnpauseModalInfo.body}
            confirmBtnText={this.state.pauseUnpauseModalInfo.confirmBtnText}
            handleConfirm={this._toggleStatus}
            handleClose={this.closePauseUnpauseModal}
            isLoading={this.state.isLoading}
          />
        )}
      </>
    );
  }
}

CodesTable.defaultProps = {
  title: "",
  text: "Date",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(CodesTable);
