import React, { Component } from "react";

export class CodeCard extends Component {
  render() {
    const { value, text } = this.props;
    return (
      <div class="col-xl-6  col-lg-6 col-md-6 col-sm-12">
        <div class="card card-xl-stretch mb-xl-8">
          <div class="card-body d-flex align-items-center pt-1 pb-0">
            <div class="d-flex flex-column flex-grow-1 py-2 py-lg-8 me-2">
              <a
                href="javascript:;"
                class="fw-bold text-dark fs-2x mb-2 text-hover-primary ff-playfair"
              >
                {value}
              </a>
              <span class="fw-semibold text-muted fs-6">{text}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CodeCard;
