import React, { Component } from "react";

import { withRouter } from "react-router-dom";

const LINK_DASHBOARD = {
  title: "Dashboard",
  icon: (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5" />
      <path
        d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
        fill="#000000"
        opacity="0.3"
      />
    </g>
  ),
  url: "/",
  text: null,
  largeIcon: true,
};

const LINK_DOWNLOADS = {
  title: "Downloads",
  icon: (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        d="M5.74714567,13.0425758 C4.09410362,11.9740356 3,10.1147886 3,8 C3,4.6862915 5.6862915,2 9,2 C11.7957591,2 14.1449096,3.91215918 14.8109738,6.5 L17.25,6.5 C19.3210678,6.5 21,8.17893219 21,10.25 C21,12.3210678 19.3210678,14 17.25,14 L8.25,14 C7.28817895,14 6.41093178,13.6378962 5.74714567,13.0425758 Z"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M11.1288761,15.7336977 L11.1288761,17.6901712 L9.12120481,17.6901712 C8.84506244,17.6901712 8.62120481,17.9140288 8.62120481,18.1901712 L8.62120481,19.2134699 C8.62120481,19.4896123 8.84506244,19.7134699 9.12120481,19.7134699 L11.1288761,19.7134699 L11.1288761,21.6699434 C11.1288761,21.9460858 11.3527337,22.1699434 11.6288761,22.1699434 C11.7471877,22.1699434 11.8616664,22.1279896 11.951961,22.0515402 L15.4576222,19.0834174 C15.6683723,18.9049825 15.6945689,18.5894857 15.5161341,18.3787356 C15.4982803,18.3576485 15.4787093,18.3380775 15.4576222,18.3202237 L11.951961,15.3521009 C11.7412109,15.173666 11.4257142,15.1998627 11.2472793,15.4106128 C11.1708299,15.5009075 11.1288761,15.6153861 11.1288761,15.7336977 Z"
        fill="#000000"
        fillRule="nonzero"
        transform="translate(11.959697, 18.661508) rotate(-270.000000) translate(-11.959697, -18.661508) "
      />
    </g>
  ),
  url: "/downloads",
  text: null,
  largeIcon: false,
};

const LINK_CHURN_RATE = {
  title: "Churn Rate",
  icon: (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        d="M21,12 C21,12.5522847 20.5522847,13 20,13 L19,13 C18.4477153,13 18,12.5522847 18,12 C18,11.4477153 18.4477153,11 19,11 L20,11 C20.5522847,11 21,11.4477153 21,12 Z M16,12 C16,12.5522847 15.5522847,13 15,13 L14,13 C13.4477153,13 13,12.5522847 13,12 C13,11.4477153 13.4477153,11 14,11 L15,11 C15.5522847,11 16,11.4477153 16,12 Z M11,12 C11,12.5522847 10.5522847,13 10,13 L9,13 C8.44771525,13 8,12.5522847 8,12 C8,11.4477153 8.44771525,11 9,11 L10,11 C10.5522847,11 11,11.4477153 11,12 Z M6,12 C6,12.5522847 5.55228475,13 5,13 L4,13 C3.44771525,13 3,12.5522847 3,12 C3,11.4477153 3.44771525,11 4,11 L5,11 C5.55228475,11 6,11.4477153 6,12 Z"
        fill="#000000"
      />
      <path
        d="M14.9596876,21 L9.04031242,21 C8.76417005,21 8.54031242,20.7761424 8.54031242,20.5 C8.54031242,20.3864643 8.5789528,20.276309 8.64987802,20.1876525 L11.6095656,16.488043 C11.7820704,16.272412 12.0967166,16.2374514 12.3123475,16.4099561 C12.3411799,16.433022 12.3673685,16.4592107 12.3904344,16.488043 L15.350122,20.1876525 C15.5226268,20.4032834 15.4876661,20.7179296 15.2720351,20.8904344 C15.1833786,20.9613596 15.0732233,21 14.9596876,21 Z M9.04031242,3 L14.9596876,3 C15.23583,3 15.4596876,3.22385763 15.4596876,3.5 C15.4596876,3.61353575 15.4210472,3.723691 15.350122,3.81234752 L12.3904344,7.51195699 C12.2179296,7.72758796 11.9032834,7.76254865 11.6876525,7.59004388 C11.6588201,7.56697799 11.6326315,7.54078935 11.6095656,7.51195699 L8.64987802,3.81234752 C8.47737324,3.59671656 8.51233393,3.28207037 8.7279649,3.1095656 C8.81662142,3.03864038 8.92677668,3 9.04031242,3 Z"
        fill="#000000"
        opacity="0.3"
      />
    </g>
  ),
  url: "/churn-rate",
  text: null,
  largeIcon: false,
};

const LINK_REVENUE = {
  title: "Revenue",
  icon: (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z"
        fill="#000000"
        opacity="0.3"
        transform="translate(11.500000, 12.000000) rotate(-345.000000) translate(-11.500000, -12.000000) "
      />
      <path
        d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z M11.5,14 C12.6045695,14 13.5,13.1045695 13.5,12 C13.5,10.8954305 12.6045695,10 11.5,10 C10.3954305,10 9.5,10.8954305 9.5,12 C9.5,13.1045695 10.3954305,14 11.5,14 Z"
        fill="#000000"
      />
    </g>
  ),
  url: "/revenue",
  largeIcon: false,
};

const PARTNER_CODES = {
  title: "Partner Codes",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />

      <path
        d="M0.353553391,7.14644661 L3.35355339,7.14644661 C3.4100716,7.14644661 3.46549471,7.14175791 3.51945496,7.13274826 C3.92739876,8.3050906 5.04222146,9.14644661 6.35355339,9.14644661 C8.01040764,9.14644661 9.35355339,7.80330086 9.35355339,6.14644661 C9.35355339,4.48959236 8.01040764,3.14644661 6.35355339,3.14644661 C5.04222146,3.14644661 3.92739876,3.98780262 3.51945496,5.16014496 C3.46549471,5.15113531 3.4100716,5.14644661 3.35355339,5.14644661 L0.436511831,5.14644661 C0.912589923,2.30873327 3.3805571,0.146446609 6.35355339,0.146446609 C9.66726189,0.146446609 12.3535534,2.83273811 12.3535534,6.14644661 L12.3535534,19.1464466 C12.3535534,20.2510161 11.4581229,21.1464466 10.3535534,21.1464466 L2.35355339,21.1464466 C1.24898389,21.1464466 0.353553391,20.2510161 0.353553391,19.1464466 L0.353553391,7.14644661 Z"
        fill="#000000"
        transform="translate(6.353553, 10.646447) rotate(-360.000000) translate(-6.353553, -10.646447) "
      />
      <rect x="2.35355339" y="13.1464466" width="8" height="2" rx="1"></rect>
      <rect x="3.35355339" y="17.1464466" width="6" height="2" rx="1"></rect>
    </g>
  ),
  url: "/codes",
  largeIcon: false,
};

const FIRST_SECTION = [
  {
    title: "",
    links: [
      LINK_DASHBOARD,
      LINK_DOWNLOADS,
      LINK_CHURN_RATE,
      LINK_REVENUE,
      PARTNER_CODES,
    ],
  },
];

class LeftMenu extends Component {
  constructor(props) {
    super(props);

    const first_section = FIRST_SECTION;

    this.state = {
      first_section,
    };
  }

  _goTo(url) {
    this.props.history.push(url);
  }

  _renderSections() {
    let { first_section } = this.state;

    const sectionTitle = (section, sectionIndex) => {
      return (
        section.title && (
          <li className="menu-section" key={sectionIndex}>
            <h4 className="menu-text">{section?.title}</h4>
            <i className="kt-menu__section-icon"></i>
          </li>
        )
      );
    };

    const sectionLink = (link, linkIndex) => {
      let className = "menu-link";
      if (link.url === window.location.pathname) {
        className += " active";
      }

      return (
        <li className="menu-item" aria-haspopup="true" key={linkIndex}>
          <a className={className} onClick={() => this._goTo(link?.url)}>
            <span className="menu-icon">
              {link?.icon && (
                <span
                  className={
                    link?.largeIcon
                      ? "svg-icon svg-icon-2x"
                      : "svg-icon svg-icon-2"
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    {link?.icon}
                  </svg>
                </span>
              )}
            </span>
            <span className="menu-title fw-bolder">{link?.title}</span>
          </a>
        </li>
      );
    };

    return (
      <div
        className="hover-scroll-overlay-y my-5 my-lg-5"
        id="kt_aside_menu_wrapper"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-height="auto"
        data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
        data-kt-scroll-wrappers="#kt_aside_menu"
        data-kt-scroll-offset={0}
        style={{ height: 194 }}
      >
        <div
          className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
          id="#kt_aside_menu"
          data-kt-menu="true"
          style={{ transform: "translateX(-1rem)" }}
        >
          <ul className="menu-nav ps-4">
            {first_section.map((section, sectionIndex) => {
              return (
                <>
                  {sectionTitle(section, sectionIndex)}

                  {section.links.map((link, linkIndex) => {
                    return sectionLink(link, linkIndex);
                  })}
                </>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div
          className="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
          id="kt_aside"
          style={{ background: "white" }}
        >
          <div className="aside-logo flex-column-auto" id="kt_aside_logo">
            <a href="/">
              <img
                alt="Logo"
                src="assets/media/logos/logo.svg"
                className="h-60px logo"
              />
            </a>

            <button
              className="brand-toggle btn btn-sm px-0"
              id="kt_aside_toggle"
            >
              <div
                id="kt_aside_toggle"
                className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
                data-kt-toggle="true"
                data-kt-toggle-target="body"
                data-kt-toggle-name="aside-minimize"
              >
                <span className="svg-icon svg-icon-1 rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.5"
                      d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                      fill="black"
                    />
                    <path
                      d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
            </button>
          </div>

          <div className="aside-menu flex-column-fluid">
            {this._renderSections()}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(LeftMenu);
