import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export class ConfirmationModal extends Component {
  render() {
    const {
      show,
      title = "Are you sure?",
      body,
      confirmBtnText,
      handleConfirm,
      handleClose,
      isLoading,
    } = this.props;

    return (
      <Modal show={show} centered>
        <div className="modal-content">
          <Modal.Body>
            <div className="swal2-icon swal2-warning swal2-icon-show d-flex">
              <div class="swal2-icon-content">!</div>
            </div>
            <h2 className="swal2-title">{title}</h2>
            <div className="swal2-html-container">{body}</div>
            <div class="swal2-actions d-flex">
              <div class="swal2-loader"></div>
              <button
                type="button"
                className="swal2-cancel swal2-styled"
                onClick={() => handleClose()}
              >
                No, cancel!
              </button>
              <button
                type="button"
                className="swal2-confirm swal2-styled"
                disabled={isLoading}
                onClick={() => handleConfirm()}
              >
                {confirmBtnText}
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    );
  }
}

export default ConfirmationModal;
