import React, { Component } from "react";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import CodeCard from "./components/cards/CodeCard";
import CodesTable from "./components/tables/codes/CodesTable";

export class Codes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statInfo: null,
    };

    // Bind the getStats function
    this.getStats = this.getStats.bind(this);
  }

  componentDidMount() {
    this.getStats();
  }

  getStats() {
    Backend.getCodesStats()
      .then((data) => {
        this.setState({ statInfo: data?.stats, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
      });
  }

  render() {
    let { statInfo } = this.state;

    return (
      <div class="d-flex flex-column-fluid">
        <div class="container">
          <h1 class=" fw-bold ff-bitter mt-2 mb-10 fs-xl-1"> Partner Codes </h1>
          <div class="row mt-5">
            <CodeCard
              value={statInfo?.all_partner_codes}
              text="All Partner Codes"
            />
            <CodeCard
              value={statInfo?.active_partner_codes_count}
              text="Active Partner Codes"
            />
          </div>

          <div className="col-lg-12 mt-5">
            <CodesTable title="Codes" getStats={this.getStats} />
          </div>
        </div>
      </div>
    );
  }
}

export default Codes;
